<template>
	<v-dialog v-model="openDialog" persistent max-width="600px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit GMB</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validatePage" ref="form">
				<p>GMB Name:</p>

				<v-text-field
					class="pa-0"
					v-model="locationName"
					type="string"
				></v-text-field>

				<p>GMB Direct Queries:</p>

				<v-text-field
					class="pa-0"
					v-model="queriesDirect"
					type="number"
				></v-text-field>
				<p>GMB Indirect Queries:</p>

				<v-text-field
					class="pa-0"
					v-model="queriesIndirect"
					type="number"
				></v-text-field>
				<p>GMB Maps View:</p>

				<v-text-field
					class="pa-0"
					v-model="viewsMaps"
					type="number"
				></v-text-field>
				<p>GMB Search Views:</p>

				<v-text-field
					class="pa-0"
					v-model="viewsSearch"
					type="number"
				></v-text-field>
				<p>GMB Phone Action:</p>

				<v-text-field
					class="pa-0"
					v-model="actionsPhone"
					type="number"
				></v-text-field>
				<p>GMB Driving Direction:</p>

				<v-text-field
					class="pa-0"
					v-model="actionsDrivingDirection"
					type="number"
				></v-text-field>
				<p>GMB Website Action:</p>

				<v-text-field
					class="pa-0"
					v-model="actionsWebsite"
					type="number"
				></v-text-field>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialog: {
			type: Boolean,
		},
	},
	computed: {
		GMBData: {
			get() {
				return this.$store.state.report.gmb
			},
		},
	},
	data() {
		return {
			openDialog: false,
			locationName: null,
			queriesDirect: null,
			queriesIndirect: null,
			viewsMaps: null,
			viewsSearch: null,
			actionsPhone: null,
			actionsDrivingDirection: null,
			actionsWebsite: null,
		}
	},
	created() {
		this.openDialog = this.dialog

		this.queriesDirect = this.GMBData.queriesDirect
		this.locationName = this.GMBData.locationName
		this.queriesIndirect = this.GMBData.queriesIndirect
		this.viewsMaps = this.GMBData.viewsMaps
		this.viewsSearch = this.GMBData.viewsSearch
		this.actionsPhone = this.GMBData.actionsPhone
		this.actionsDrivingDirection = this.GMBData.actionsDrivingDirection
		this.actionsWebsite = this.GMBData.actionsWebsite
	},
	methods: {
		validatePage: function () {
			let payload = {
				openDialog: this.openDialog,
				locationName: this.locationName,
				queriesDirect: this.queriesDirect,
				queriesIndirect: this.queriesIndirect,
				viewsMaps: this.viewsMaps,
				viewsSearch: this.viewsSearch,
				actionsPhone: this.actionsPhone,
				actionsDrivingDirection: this.actionsDrivingDirection,
				actionsWebsite: this.actionsWebsite,
			}
			this.$store.dispatch('report/validateGMBData', {
				GMBData: payload,
			})
			this.resetValidation()
		},
		resetValidation() {
			this.$emit('closeModal')
		},
	},
}
</script>

<style scoped>
.fill-width {
	overflow-x: auto;
	flex-wrap: nowrap;
}
</style>
