<template>
	<v-card class="pa-6" max-width="1200">
		<div class="d-flex flex-row justify-space-between align-end mb-5">
			<h1 class="text-h6">New Pages ({{ pages.length }})</h1>
			<v-btn
				color="var(--blue)"
				dark
				right
				depressed
				rounded
				elevation="0"
				class="add-more py-0 px-4"
				@click="showDialog = !showDialog"
			>
				<v-icon left>mdi-plus</v-icon> Add
			</v-btn>
		</div>

		<Pages v-for="(page, index) in pages" :key="index" :page="page"></Pages>

		<PageForm
			v-if="showDialog"
			:dialog="showDialog"
			@closeModal="closeModal"
		></PageForm>
	</v-card>
</template>

<script>
import Pages from './Pages'
import PageForm from './PageForm'
export default {
	components: {
		Pages,
		PageForm,
	},
	data() {
		return {
			showDialog: false,
		}
	},
	computed: {
		pages: {
			get() {
				return this.$store.state.report.newPages
			},
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
	},
}
</script>

<style scoped>
.add-more {
	color: white;
	font-size: 13px !important;
}
.add-more i {
	font-size: 18px !important;
}
</style>
