<template>
	<div>
		<v-card v-if="!isLoading" class="pa-8 pb-6">
			<v-dialog v-model="initialDialog" persistent max-width="600px">
				<v-card class="pa-6 mb-0">
					<div>
						<v-radio-group v-model="reportType" row>
							<v-radio label="Manual" value="manual"></v-radio>
							<v-radio
								label="Fetch from project"
								value="automated"
							></v-radio>
						</v-radio-group>
					</div>
					<div>
						<v-row class="mt-5">
							<v-col cols="6" class="ma-0 pa-0 pr-8">
								<v-menu
									v-model="fromStartDateMenu"
									:close-on-content-click="true"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											label="Start Date*"
											readonly
											:value="startDate"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="datePickerStartDate"
										no-title
										:max="maxDate"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="5" offset-lg="1" class="py-0 pr-0">
								<v-menu
									v-model="fromEndDateMenu"
									:close-on-content-click="true"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on }">
										<v-text-field
											label="End Date*"
											readonly
											:value="endDate"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="datePickerEndDate"
										no-title
										:min="datePickerStartDate"
										:max="maxDate"
									></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
					</div>

					<div>
						<v-row class="mt-5">
							<v-col cols="1" class="pa-0"> GMB: </v-col>
							<v-col cols="8" class="pa-0">
								<v-select
									:items="locations"
									@input="setSelected"
									label="Select Location"
									outlined
									dense
								></v-select>
							</v-col>
						</v-row>
					</div>
					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
						:disabled="initialDataReceived"
						@click="closeInitialModal()"
					>
						Done
					</v-btn>
				</v-card>
			</v-dialog>
			<v-row no-gutters>
				<v-row>
					<v-col cols="8" class="pb-3">
						<p class="ma-0 label">Title* :</p>
						<v-text-field
							placeholder="Enter Title"
							v-model="title"
							required
						></v-text-field>
					</v-col>
					<v-col cols="2" class="pb-3">
						<v-menu
							v-model="fromModalStartDateMenu"
							:close-on-content-click="true"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									label="Start Date*"
									readonly
									:value="startDate"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="datePickerStartDate"
								no-title
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="2" class="pb-3">
						<v-menu
							v-model="fromModalEndDateMenu"
							:close-on-content-click="true"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									label="End Date*"
									readonly
									:value="endDate"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="datePickerEndDate"
								no-title
								:min="datePickerStartDate"
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>
				<v-col cols="12">
					<PagesCard></PagesCard>
					<GMBCard></GMBCard>
					<AdditionalData></AdditionalData>
				</v-col>
			</v-row>
			<v-card-actions class="card-actions px-0">
				<UnoSaveButton
					@click.native="saveData()"
					@primary-button-click="reportListing()"
					primary-button-text="Move To Report Listing"
					secondary-button-text="Continue Editing"
					:saving="isSaving"
					:alert="alert"
				></UnoSaveButton>

				<v-spacer></v-spacer>
				<UnoDeleteButton
					v-if="isEdit"
					@confirm="deleteReport()"
				></UnoDeleteButton>
			</v-card-actions>
		</v-card>
		<v-tooltip bottom color="primary">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					color="primary"
					class="download-btn"
					elevation="4"
					fixed
					right
					bottom
					fab
					@click="downloadWord()"
					><span class="mdi mdi-download"></span
				></v-btn>
			</template>
			<span class="tooltip-text">Download Data</span>
		</v-tooltip>
	</div>
</template>
<script>
// ACTION BUTTONS
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import PagesCard from './components/PagesCard'
import GMBCard from './components/GMBCard.vue'
import AdditionalData from './components/AdditionalData.vue'
import moment from 'moment'
// import { Document, Paragraph, Packer } from 'docx'
// import { saveAs } from 'file-saver'
export default {
	components: {
		UnoSaveButton,
		UnoDeleteButton,
		PagesCard,
		AdditionalData,
		GMBCard,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			alert: {
				show: null,
				message: null,
				error: false,
			},
			maxDate: moment(new Date()).format('YYYY-MM-DD'),
			fromStartDateMenu: false,
			fromEndDateMenu: false,
			fromModalStartDateMenu: false,
			fromModalEndDateMenu: false,
			isSaving: false,
			reportId: null,
			locations: [],
			reportType: null,
			locationName: null,
			initialData: false,
		}
	},
	destroyed() {
		this.$store.commit('report/clearState')
	},
	computed: {
		title: {
			get() {
				return this.$store.state.report.title
			},
			set(value) {
				this.$store.commit('report/updateTitle', value)
			},
		},
		datePickerStartDate: {
			get() {
				return this.startDate
					? moment(this.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
					: null
			},
			set(value) {
				this.startDate = value
			},
		},
		datePickerEndDate: {
			get() {
				return this.endDate
					? moment(this.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
					: null
			},
			set(value) {
				this.endDate = value
			},
		},
		initialDataReceived: {
			get() {
				if (
					(this.reportType === 'manual' &&
						this.startDate &&
						this.endDate) ||
					(this.reportType === 'automated' &&
						this.startDate &&
						this.endDate &&
						this.locationName)
				) {
					return false
				} else {
					return true
				}
			},
		},
		startDate: {
			get() {
				return this.$store.state.report.timeLine.startDate
					? moment(
							this.$store.state.report.timeLine.startDate,
							'YYYY-MM-DD'
					  ).format('DD-MM-YYYY')
					: null
			},
			set(value) {
				this.$store.commit('report/updateStartDate', value)
			},
		},
		endDate: {
			get() {
				return this.$store.state.report.timeLine.endDate
					? moment(
							this.$store.state.report.timeLine.endDate,
							'YYYY-MM-DD'
					  ).format('DD-MM-YYYY')
					: null
			},
			set(value) {
				this.$store.commit('report/updateEndDate', value)
			},
		},
		gmb: {
			get() {
				return this.$store.state.report.gmb
			},
		},
		additionalData: {
			get() {
				return this.$store.state.report.additionalData
			},
		},
		newPages: {
			get() {
				return this.$store.state.report.newPages
			},
		},
		isLoading: {
			get() {
				return this.$store.state.report.isLoading
			},
		},
		isEdit: {
			get() {
				return this.$store.state.report.isEdit
			},
			set(value) {
				this.$store.commit('report/setIsEdit', value)
			},
		},
		initialDialog: {
			get() {
				return this.isEdit || this.initialData ? false : true
			},
		},
		DomainName() {
			return this.$store.getters['app/getCurrentProjectDomain']
		},
	},
	// validations: {
	// 	title: {
	// 		required,
	// 		minLength: minLength(5),
	// 	},
	// 	body: {
	// 		required,
	// 		minLength: minLength(17),
	// 	},
	// 	description: {
	// 		required,
	// 		minLength: minLength(5),
	// 	},
	// },
	created() {
		if (this.id) {
			this.reportId = this.id
			this.$store.commit('report/setIsEdit', true)
			this.$store.dispatch('report/getInitialData', {
				id: this.id,
			})
		} else {
			this.getGmbLocations()
			this.$store.commit('report/setIsLoading', false)
		}
	},
	// watch: {
	// 	$route: 'getBlog',
	// 	title() {
	// 		this.errors.title = ''
	// 	},
	// 	description() {
	// 		this.errors.description = ''
	// 	},
	// 	body() {
	// 		this.errors.body = ''
	// 	},
	// 	altText() {
	// 		this.altTextChanged()
	// 	},
	// },
	methods: {
		// titleErrors() {
		// 	const errors = []
		// 	if (!this.$v.title.$dirty) return errors

		// 	!this.$v.title.minLength &&
		// 		errors.push('Title must be atleast 4 letters long')
		// 	!this.$v.title.required && errors.push('Title is required.')

		// 	return errors
		// },

		// validateData: function () {
		// 	let isValid = true
		// 	if (this.altText === '') {
		// 		this.errors.altText = 'Alt Text field should not be empty.'
		// 		isValid = false
		// 	} else {
		// 		this.errors.altText = ''
		// 	}
		// 	if (this.title === '') {
		// 		this.errors.title = 'Title field should not be empty.'
		// 		isValid = false
		// 	} else {
		// 		this.errors.title = ''
		// 	}
		// 	if (this.description === '') {
		// 		this.errors.description =
		// 			'Blog description should not be empty.'
		// 		isValid = false
		// 	}
		// 	if (this.body === '') {
		// 		this.errors.body = 'Blog Content should not be empty.'
		// 		isValid = false
		// 	} else {
		// 		this.errors.body = ''
		// 	}
		// 	if (isValid) {
		// 		this.saveData()
		// 	}
		// },
		showDate(date) {
			return date ? moment(date).format('DD-MM-YYYY') : ''
		},
		downloadWord() {
			let fileContents = `${this.startDate} - ${this.endDate}\r\r`
			fileContents = fileContents + `New Pages\r`
			this.newPages.map((page) => {
				fileContents = fileContents + `${page.url}\r`
			})
			fileContents = fileContents + '\r'
			fileContents = fileContents + '\rGMB\r'
			for (var key in this.gmb) {
				fileContents = fileContents + `${key} - ${this.gmb[key]}\r`
			}
			fileContents = fileContents + '\r'
			this.additionalData.map((data) => {
				fileContents = fileContents + `\r${data.heading}\r`
				data.values.map((value) => {
					fileContents =
						fileContents +
						`${value.value} ${
							value.date
								? '->'.concat(this.showDate(value.date))
								: ''
						}` +
						`\r`
				})
				fileContents = fileContents + '\r'
			})
			if (typeof Blob != 'undefined') {
				// using Blob
				var textFileAsBlob = new Blob([fileContents], {
					type: 'text/plain',
				})
				var downloadLink = document.createElement('a')
				downloadLink.download = this.title ? this.title : 'Report'
				if (window.webkitURL != null) {
					downloadLink.href =
						window.webkitURL.createObjectURL(textFileAsBlob)
				} else {
					downloadLink.href =
						window.URL.createObjectURL(textFileAsBlob)
					downloadLink.onclick = document.body.removeChild(
						event.target
					)
					downloadLink.style.display = 'none'
					document.body.appendChild(downloadLink)
				}
				downloadLink.click()
			} else {
				var pp = document.createElement('a')
				pp.setAttribute(
					'href',
					'data:text/plain;charset=utf-8,' +
						encodeURIComponent(fileContents)
				)
				pp.setAttribute('download', this.title ? this.title : 'Report')
				pp.onclick = document.body.removeChild(event.target)
				pp.click()
			}
		},

		getGmbLocations() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'get',
				url: `/${projectId}/gmb/locations`,
			}).then((res) => {
				this.locationsArr = res.data.data
				res.data.data.map((location) => {
					let locationName = `${location.locationName} (${location.address})`
					this.locations = [...this.locations, locationName]
				})
			})
		},
		setSelected(locationName) {
			this.locationName = locationName
			this.$store.commit('report/setLocationName', locationName)
		},
		closeInitialModal() {
			if (this.reportType === 'automated') {
				const projectId = this.$store.state.app.projectId
				let locationName = null
				this.locationsArr.forEach((location) => {
					if (
						`${location.locationName} (${location.address})` ===
						this.locationName
					) {
						locationName = location.name
					}
				})
				this.axios({
					method: 'post',
					url: `/${projectId}/reports/fetch`,
					data: {
						startDate: moment(this.startDate, 'DD-MM-YYYY').format(
							'YYYY-MM-DD'
						),
						endDate: moment(this.endDate, 'DD-MM-YYYY').format(
							'YYYY-MM-DD'
						),
						locationName,
					},
				}).then(async (res) => {
					const newPages = await res.data.data.newPages.map(
						(page) => {
							return {
								url: `${this.DomainName}${page.publicPath}`,
								createdAt: page.createdAt,
							}
						}
					)
					this.$store.commit('report/fetchedNewPages', newPages)
					this.$store.commit(
						'report/updateGMBData',
						res.data.data.gmb
					)
					// this.locationsArr = res.data.data
					// res.data.data.map((location) => {
					// 	let locationName = `${location.locationName} (${location.address})`
					// 	this.locations = [...this.locations, locationName]
					// })
				})
			}
			this.initialData = true
		},
		saveData() {
			this.isSaving = true
			const isEdit = this.isEdit
			const projectId = this.$store.state.app.projectId
			const payload = {
				title: this.title,
				gmb: this.gmb,
				newPages: this.newPages,
				additionalData: this.additionalData,
				timeLine: {
					startDate: this.datePickerStartDate,
					endDate: this.datePickerEndDate,
				},
			}
			this.axios({
				method: isEdit ? 'put' : 'post',
				url: isEdit
					? `/${projectId}/reports/${this.reportId}`
					: `/${projectId}/reports`,
				data: payload,
			})
				.then((res) => {
					this.isSaving = false
					if (res.status === 404) {
						console.log(404)
					}

					if (res.status == 200 && res.data.success) {
						this.alert.message = res.data.message
						this.isEdit = true
						this.reportId = res.data.data.id
						// const data = res.data.data
						// this.setReportData(data)
						// this.alert.message = res.data.message
					}
				})
				.catch((error) => {
					console.log('error in report', error)
				})
		},
		deleteReport() {
			this.$store.dispatch('report/reportDelete', {
				reportId: this.id,
			})
			this.$router.push({ name: 'report.list' })
		},

		reportListing() {
			this.$router.push({
				name: 'report.list',
			})
		},
	},
}
</script>

<style scoped>
.download-btn {
	margin: 40px;
	font-size: 21px;
}
</style>
