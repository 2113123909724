<template>
	<v-dialog v-model="openDialog" persistent max-width="600px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit additional pages</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<div>
				<v-col cols="12" class="ma-0 pa-0 pb-2 pr-4">
					<p>Heading*</p>
					<v-text-field class="pa-0" v-model="heading"></v-text-field>
				</v-col>
				<v-row>
					<v-col cols="6" class="ma-0 pa-0 pb-2 pr-4">
						<p>Values</p>
						<v-text-field
							class="pa-0"
							v-model="value"
							@keyup.enter="updateValues()"
						></v-text-field>
					</v-col>
					<v-col cols="6" class="ma-0 pa-3 pr-8">
						<v-menu
							v-model="dateMenu"
							:close-on-content-click="true"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									label="Date"
									readonly
									:value="showDate(date)"
									@keyup.enter="updateValues()"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								no-title
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col>
						<div
							v-for="value in values"
							:key="value.uuid"
							class="chip"
							close
						>
							<span
								@click="removeValue(value.uuid)"
								class="mdi mdi-close"
							></span>

							<div>
								{{
									`${value.value} ${
										value.date
											? '->'.concat(showDate(value.date))
											: ''
									}`
								}}
							</div>
						</div>
					</v-col>
				</v-row>
				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteAdditionalData(uuid)"
					>
						Delete
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
						@click="validate()"
					>
						Done
					</v-btn>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export default {
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			heading: null,
			dateMenu: false,
			value: null,
			date: null,
			values: [],
			uuid: null,
		}
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.heading = this.initialData.heading
			this.values = this.initialData.values.map((value) => {
				return {
					uuid: uuidv4(),
					value: value.value,
					date: value.date ? value.date : null,
				}
			})
			this.uuid = this.initialData.uuid
		}
	},

	methods: {
		showDate(date) {
			return date ? moment(date).format('DD-MM-YYYY') : ''
		},
		updateValues() {
			if (this.value.trim().length > 0) {
				let obj = {
					value: this.value,
					date: this.date ? this.date : null,
					uuid: uuidv4(),
				}
				let valuesArray = this.values
				valuesArray.push(obj)

				this.value = ''
				this.date = null
			}
		},
		removeValue(id) {
			let valueArray = this.values
			valueArray.every(function (value, index) {
				if (value.uuid === id) {
					valueArray.splice(index, 1)
					return false //break the loop
				} else {
					return true
				}
			})
			this.values = valueArray
		},
		validate: function () {
			const values = this.values.map((value) => {
				return {
					value: value.value,
					date: value.date,
				}
			})
			let payload = {
				heading: this.heading,
				values,
				uuid: this.uuid,
			}

			this.$store.dispatch('report/validateAdditionalData', {
				additionalData: payload,
				isEdit: this.isEdit,
			})
			this.resetValidation()
		},
		deleteAdditionalData(id) {
			this.$store.commit('report/deleteAdditionalData', id)
		},
		resetValidation() {
			this.$emit('closeModal')
		},
	},
}
</script>

<style scoped>
.chip {
	background-color: rgb(182, 160, 160);
	color: black;
	padding: 0px 6px 0px 6px;
	margin: 2px;
	border-radius: 15px;
	position: relative;
	border: 1px solid;
}

span {
	float: right;
	size: 15px;
}
</style>
