<template>
	<v-dialog v-model="openDialog" persistent max-width="600px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit new pages added</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validatePage" ref="form">
				<v-col cols="12" class="ma-0 pa-0 pb-2 pr-4">
					<p>Url*</p>
					<v-text-field
						class="pa-0"
						v-model="url"
						placeholder="google.com"
					></v-text-field>
				</v-col>

				<v-row>
					<v-col align="center" cols="12" class="ma-0 pa-0">
						<p>Date Added*</p>
						<v-date-picker
							v-model="createdAt"
							:max="maxDate"
						></v-date-picker>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deletePage(uuid)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			url: null,
			createdAt: null,
			uuid: null,
			maxDate: moment().format('YYYY-MM-DD'),
		}
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.url = this.initialData.url
			this.createdAt = moment(
				this.initialData.createdAt,
				'DD-MM-YYYY'
			).format('YYYY-MM-DD')

			this.uuid = this.initialData.uuid
		}
	},

	methods: {
		validatePage: function () {
			let payload = {
				url: this.url,
				createdAt: this.createdAt,
				uuid: this.uuid,
			}
			this.$store.dispatch('report/validatePageData', {
				pageData: payload,
				isEdit: this.isEdit,
			})
			this.resetValidation()
		},
		deletePage(id) {
			this.$store.commit('report/deletePage', id)
		},
		resetValidation() {
			this.$emit('closeModal')
		},
	},
}
</script>
